import type { IObject } from '@/types';

export const objects: IObject[] = [
  {
    id: 1,
    name: 'GALHARDETE PORTUGAL',
    image: {
      path: '/objects/box.png',
      mime_type: 'image/jpeg',
      caption: '1',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date('2023-08-15'),
    category: 'COLEÇÃO',
    medias: [
      {
        caption: 'Saudi Arabia',
        path: 'https://images.unsplash.com/photo-1682685797229-b2930538da47?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Mountain Silhouette',
        path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        mime_type: 'video/mp4',
        thumbnail:
          'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        caption: 'Punakaiki Coast',
        path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Golden Hour',
        path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Sandstone plateau',
        path: 'https://images.unsplash.com/photo-1682687218608-5e2522b04673?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
    ],
    shelly: {
      device_id: 1,
      channel: 1,
    },
  },
  {
    id: 2,
    name: 'GALHARDETE PORTUGAL',
    image: {
      path: '/objects/football.png',
      mime_type: 'image/jpeg',
      caption: '2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date('2023-09-10'),
    category: 'COLEÇÃO',
    medias: [
      {
        caption: 'Saudi Arabia',
        path: 'https://images.unsplash.com/photo-1682685797229-b2930538da47?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Mountain Silhouette',
        path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        mime_type: 'video/mp4',
      },
      {
        caption: 'Punakaiki Coast',
        path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Golden Hour',
        path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Sandstone plateau',
        path: 'https://images.unsplash.com/photo-1682687218608-5e2522b04673?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
    ],
    shelly: {
      device_id: 1,
      channel: 2,
    },
  },
  {
    id: 3,
    name: 'GALHARDETE PORTUGAL',
    image: {
      path: '/objects/portugal-flag.png',
      mime_type: 'image/jpeg',
      caption: '3',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date('2023-09-20'),
    category: 'COLEÇÃO',
    medias: [
      {
        caption: 'Saudi Arabia',
        path: 'https://images.unsplash.com/photo-1682685797229-b2930538da47?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Mountain Silhouette',
        path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        mime_type: 'video/mp4',
      },
      {
        caption: 'Punakaiki Coast',
        path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Golden Hour',
        path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Sandstone plateau',
        path: 'https://images.unsplash.com/photo-1682687218608-5e2522b04673?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
    ],
    shelly: {
      device_id: 1,
      channel: 2,
    },
  },
  {
    id: 4,
    name: 'GALHARDETE PORTUGAL',
    image: {
      path: '/objects/red-box.png',
      mime_type: 'image/jpeg',
      caption: '4',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date('2023-09-20'),
    category: 'COLEÇÃO',
    medias: [
      {
        caption: 'Saudi Arabia',
        path: 'https://images.unsplash.com/photo-1682685797229-b2930538da47?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Mountain Silhouette',
        path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        mime_type: 'video/mp4',
      },
      {
        caption: 'Punakaiki Coast',
        path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Golden Hour',
        path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Sandstone plateau',
        path: 'https://images.unsplash.com/photo-1682687218608-5e2522b04673?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
    ],
    shelly: {
      device_id: 1,
      channel: 2,
    },
  },
  {
    id: 5,
    name: 'GALHARDETE PORTUGAL',
    image: {
      path: '/objects/box.png',
      mime_type: 'image/jpeg',
      caption: '5',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date('2023-09-20'),
    category: 'COLEÇÃO',
    medias: [
      {
        caption: 'Saudi Arabia',
        path: 'https://images.unsplash.com/photo-1682685797229-b2930538da47?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Mountain Silhouette',
        path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        mime_type: 'video/mp4',
      },
      {
        caption: 'Punakaiki Coast',
        path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Golden Hour',
        path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
      {
        caption: 'Sandstone plateau',
        path: 'https://images.unsplash.com/photo-1682687218608-5e2522b04673?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        mime_type: 'image/jpeg',
      },
    ],
    shelly: {
      device_id: 1,
      channel: 2,
    },
  },
];
