import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const PrivateRoutes = () => {
  const auth = { token: true };
  const location = useLocation();

  // get user or token checks here and check if its valid or not
  if (auth.token) {
    return <Outlet />;
  }
  return <Navigate to='/login' state={{ from: location }} replace />;
};
