export const CHEVRON_LEFT = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      id='Vector'
      d='M9.9103 0.900024L1.8103 9.00002L9.9103 17.1'
      stroke={color ?? 'rgb(var(--color-chalk))'}
      strokeWidth='2.025'
    />
  </svg>
);
