import { ROUTES } from '@/config';
import { GallerySlider, Stack } from '@/core/blocks';
import { Icon } from '@/core/icon';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  HtmlMarkup,
  Image,
  Typography,
} from '@/core/ui';
import { cn } from '@/lib';
import { API } from '@/store/api';
import { getFormattedDate } from '@/utils';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ObjectDetailSkeleton } from './view.skeleton';
import { useSendCommandMutation } from '@/store/onecore/api';

export const ObjectDetails = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation('translation');

  const {
    isLoading,
    isFetching,
    data: object,
    error,
  } = API.objectApi.useGetObjectByIdQuery(
    { id: Number.parseInt(id as string), locale: i18n.language },
    { skip: !id || !i18n.language },
  );

  const [sendCommand] = useSendCommandMutation();

  const { data: configData } = API.oneCoreApi.useGetOneCoreConfigQuery();

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !object) {
    return <ObjectDetailSkeleton />;
  }

  const onPush = async () => {
    if (!configData?.data?.settings) {
      console.error('Configuration data is not available.');
      return;
    }

    try {
      const { IP_ADDRESS, TOKEN } = configData.data.settings;

      await sendCommand({
        app: 'Objects',
        address: IP_ADDRESS,
        key: TOKEN,
        command: 2,
        options: { a: 123 },
        devices: [object.shelly.device_id],
      }).unwrap();

      console.log('Command sent successfully');
    } catch (error) {
      console.error('Failed to send command:', error);
    }
  };

  return (
    <div className='relative grid bg-apple h-full w-full shadow-container grid-cols-8 rounded-2xl'>
      <div
        className={cx(
          'bg-contain bg-bottom bg-trophy bg-olive',
          'rounded-tl-2xl rounded-bl-2xl col-span-3 relative',
        )}
      >
        {!!object.medias.length && (
          <Dialog>
            <DialogTrigger asChild>
              <div className='absolute top-[49%] rounded-full bg-chalk/20 left-[6.2rem] backdrop-blur-lg'>
                <Icon name='PLUS' />
              </div>
            </DialogTrigger>
            <DialogContent
              closeJsx={
                <DialogClose className='absolute right-8 top-8 rounded-sm ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'>
                  <Icon name={'X_ICON'} className='bg-danger rounded-full p-2 w-16 h-16' color='white' />
                  <span className='sr-only'>Close</span>
                </DialogClose>
              }
              className='bg-transparent flex justify-center items-center shadow-none'
            >
              <DialogTitle />
              <DialogDescription>
                <GallerySlider slides={object.medias} />
              </DialogDescription>
            </DialogContent>
          </Dialog>
        )}
        <div className='flex justify-center h-full items-center'>
          <Image src={object.image.path} alt={object.name} className='h-auto w-[80%]' />
        </div>
      </div>
      <Link
        to={ROUTES.MAIN}
        className={cn(
          'absolute top-8 right-8',
          'flex px-6 py-3.5 bg-danger rounded-full items-center space-x-1.5',
        )}
        onClick={onPush}
      >
        <Icon className='h-4 w-4' name='CHEVRON_LEFT' />
        <Icon className='h-5 w-5' name='HOME' />
      </Link>
      <div className='px-28 pb-24 pt-28 flex flex-col justify-between col-span-5'>
        <div className='flex flex-col space-y-14'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-col'>
              <Typography className='font-bold uppercase max-w-2xl text-5xl leading-[44.65px] tracking-[3.29px]'>
                {object.name}
              </Typography>
            </div>
            <div className='flex flex-col text-end break-words max-w-40'>
              <Typography className='text-2xl leading-6 tracking-[1.2px] uppercase font-semibold'>
                {t('detail.object')}
              </Typography>
              <Typography className='text-2xl leading-6 uppercase tracking-[1.2px] font-semibold'>
                {t('detail.history')}
              </Typography>
            </div>
          </div>
          <div className='max-w-[44rem] flex flex-col'>
            <HtmlMarkup className='prose no-scrollbar text-[26px] max-h-[26rem] overflow-y-scroll font-united-sans font-medium tracking-[1.2px] leading-8'>
              {object.description}
            </HtmlMarkup>
          </div>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <div className='flex items-center space-x-4'>
            <Stack upperClass='h-[20px] w-[10px]' lowerClass='h-[14px] w-[10px]' />

            <Typography className='text-[45px] uppercase pt-2 leading-[42.75px] font-semibold tracking-[3.15px]'>
              {object.category}
            </Typography>
          </div>

          <Typography className='text-[45px] uppercase pt-2 text-right leading-[42.75px] font-bold tracking-[3.15px]'>
            {getFormattedDate(object.createdAt)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
