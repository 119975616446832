import Cookies from 'js-cookie';

export const Storage = {
  local: {
    /**
     * Sets the value of the pair identified by key to value in Local Storage
     * @param {string} key
     * @param {unknown} value
     */
    set: (key: string, value: unknown): void => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error('Storage.set ERROR::', error);
      }
    },
    /**
     * Returns the current value associated with the given key, or null if the given key does not exist.
     * @param {string} key
     */
    get: <T = unknown>(key: string): T | null => {
      try {
        const parsedData = JSON.parse(localStorage.getItem(key) as string) as T;
        return parsedData;
      } catch (error) {
        console.error('Storage.get ERROR::', error);
        return null;
      }
    },
    getRaw: <T = unknown>(key: string): T | null => {
      try {
        const parsedData = localStorage.getItem(key) as string as T;
        return parsedData;
      } catch (error) {
        console.error('Storage.getRaw ERROR::', error);
        return null;
      }
    },

    /**
     * Store the Key with expiry in Local Storage
     * @param {string} key
     * @param {string} value
     * @param {number} ttl
     */
    setWithExpiry: (key: string, value: string, ttl: number): void => {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },

    /**
     * Returns the current value associated with the given key, or null if the given key does not exist
     * @param {string} key
     */
    getWithExpiry: <T = unknown>(key: string): T | null => {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr) as { expiry: number; value: T };
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
  },
  session: {
    set: (key: string, value: string, maxAge?: number) => {
      Cookies.set(key, value, { expires: maxAge ? maxAge / 86400 : undefined, path: '/' }); // maxAge is in seconds, convert to days for js-cookie
    },
    get: <T = unknown>(key: string): T | null => {
      const value = Cookies.get(key);
      return value ? (value as unknown as T) : null;
    },
    destroy: (key: string) => {
      Cookies.remove(key, { path: '/' });
    },
  },
};
