import { Skeleton, Typography } from '@/core/ui';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';

export const HomeHeading = () => {
  const { i18n } = useTranslation();
  const { isLoading, isFetching, data } = API.objectApi.useGetObjectsQuery(
    { locale: i18n.language },
    {
      // selectFromResult: ({ data }) => ({
      //   appTitle: data?.app_title,
      // }),
      skip: !i18n.language,
    },
  );

  if (isLoading || isFetching || !data) {
    return (
      <div className='flex flex-col bg-apple items-center space-y-6'>
        <Skeleton className='h-16 w-[20rem]' />
        <Skeleton className='h-16 w-[28rem]' />
      </div>
    );
  }

  const splitWord = data.app_title.split(/ (.+)/);

  return (
    <div className='flex flex-col items-center space-y-0'>
      <Typography className='text-7xl uppercase tracking-[5.2px] font-bold'>{splitWord[0]}</Typography>
      <Typography className='text-7xl uppercase tracking-[5.2px] font-bold'>{splitWord[1]}</Typography>

      {/* <TypewriterEffectSmooth
        words={t('home.title')
          .split(' ')
          .map((w) => ({ text: w, className: 'text-7xl uppercase tracking-[5.2px] font-bold' }))}
      />

      <TypewriterEffectSmooth
        words={t('home.subtitle')
          .split(' ')
          .map((w) => ({ text: w, className: 'text-7xl uppercase tracking-[5.2px] font-bold' }))}
      /> */}
    </div>
  );
};
