export const GLOBE = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='34' height='40' viewBox='0 0 34 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Icon/Globe'>
      <g id='Group 3243'>
        <path
          id='Vector'
          d='M10.6406 40.0001C10.4333 39.9313 10.2229 39.873 10.0198 39.7928C9.01874 39.4001 8.48436 38.4865 8.67499 37.4292C9.43957 33.1688 10.25 28.9157 10.975 24.648C11.3323 22.548 11.6042 20.4282 11.7948 18.3074C11.9323 16.7709 11.6135 16.4376 10.1489 15.9792C7.93332 15.2855 5.70936 14.6188 3.50624 13.8876C2.83853 13.6657 2.17186 13.3469 1.60415 12.9344C0.273945 11.9688 0.55832 9.61985 2.04582 8.90735C2.49478 8.69277 2.94894 8.71464 3.41144 8.85527C6.70832 9.85631 10.0614 10.5948 13.4739 11.0698C16.1739 11.4459 18.8604 11.3303 21.5437 10.9021C24.5719 10.4188 27.5489 9.71464 30.4927 8.86256C31.45 8.58548 32.2104 8.87298 32.7219 9.73548C33.3594 10.8126 33.5937 12.3584 31.825 13.2678C31.0219 13.6803 30.1552 13.974 29.3021 14.2803C27.4469 14.9469 25.5739 15.5646 23.7239 16.2438C22.1021 16.8386 21.9167 17.0823 22.075 18.8865C22.4187 22.7917 23.2229 26.6261 23.9385 30.473C24.3646 32.7605 24.7979 35.0469 25.2135 37.3365C25.4375 38.5709 24.8062 39.549 23.5989 39.8917C23.4896 39.923 23.3823 39.9626 23.2739 39.999H22.2604C21.0521 39.673 20.525 38.7751 20.2906 37.6355C20.1625 37.0115 20.0614 36.3823 19.9187 35.7615C19.2739 32.9511 18.6333 30.1396 17.9552 27.3376C17.8073 26.7251 17.5427 26.1344 17.2729 25.5605C17.0458 25.0771 16.8208 25.0959 16.5531 25.5688C16.4635 25.7261 16.3812 25.8907 16.3229 26.0626C16.1104 26.6896 15.8583 27.3094 15.7146 27.9521C15.0198 31.0709 14.3437 34.1948 13.6854 37.3219C13.4271 38.5501 13.0094 39.6209 11.6552 39.999H10.6417L10.6406 40.0001Z'
          fill={color ?? 'rgb(var(--color-chalk))'}
        />
        <path
          id='Vector_2'
          d='M17.5021 0C17.7625 0.0708333 18.026 0.13125 18.2833 0.213542C20.2771 0.85 21.5979 2.91458 21.2885 4.90313C20.9375 7.15938 19.1656 8.73958 16.976 8.74896C14.825 8.75833 12.9916 7.19271 12.6531 5.05729C12.2802 2.7 13.8531 0.486458 16.1937 0.0729167C16.2677 0.059375 16.3375 0.025 16.4104 0.00104167C16.7739 0.00104167 17.1385 0.00104167 17.5021 0.00104167V0Z'
          fill={color ?? 'rgb(var(--color-chalk))'}
        />
      </g>
    </g>
  </svg>

  // <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
  //   <g id='Group 3222'>
  //     <path
  //       id='Oval (Stroke)'
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M0 12C0 5.37259 5.37259 0 12 0C18.6274 0 24 5.37259 24 12C24 18.6274 18.6274 24 12 24C5.37259 24 0 18.6274 0 12ZM12 2.18182C6.57758 2.18182 2.18182 6.57758 2.18182 12C2.18182 17.4225 6.57758 21.8182 12 21.8182C17.4225 21.8182 21.8182 17.4225 21.8182 12C21.8182 6.57758 17.4225 2.18182 12 2.18182Z'
  //       fill='white'
  //     />
  //     <path
  //       id='Shape (Stroke)'
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M0 12.0001C0 11.3976 0.488417 10.9092 1.09091 10.9092H22.9091C23.5116 10.9092 24 11.3976 24 12.0001C24 12.6026 23.5116 13.091 22.9091 13.091H1.09091C0.488417 13.091 0 12.6026 0 12.0001Z'
  //       fill='white'
  //     />
  //     <path
  //       id='Shape (Stroke)_2'
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M12.3945 0.0739044C12.557 0.136929 12.698 0.23607 12.8098 0.360032C15.7141 3.54193 17.3645 7.67007 17.4543 11.9773C17.4546 11.9925 17.4546 12.0076 17.4543 12.0228C17.3645 16.3322 15.7125 20.4624 12.8054 23.6449C12.5987 23.8712 12.3064 24.0001 12 24.0001C11.6935 24.0001 11.4012 23.8712 11.1945 23.6449C8.28748 20.4624 6.63542 16.3322 6.54565 12.0228C6.54533 12.0076 6.54533 11.9925 6.54565 11.9773C6.63542 7.6679 8.28748 3.53777 11.1945 0.355219C11.4981 0.0227973 11.9748 -0.0889424 12.3945 0.0739044ZM8.72748 12.0001C8.80225 15.3544 9.95587 18.5839 12 21.2174C14.044 18.5839 15.1977 15.3544 15.2724 12.0001C15.1977 8.64568 14.0441 5.41625 12 2.78273C9.95587 5.41625 8.80225 8.64568 8.72748 12.0001Z'
  //       fill='white'
  //     />
  //   </g>
  // </svg>
);
