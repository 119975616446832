import type { Robot } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const robotApi = createApi({
  reducerPath: 'robotApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: 'https://jsonplaceholder.typicode.com/',
    mock: false,
  }),
  tagTypes: ['robots'],
  endpoints: (builder) => ({
    getRobots: builder.query<Robot[], null>({
      query: () => ({ url: 'users', isProtected: false }),
    }),
    getRobotsById: builder.query<Robot, { id: string }>({
      query: ({ id }) => ({ url: `users/${id}`, isProtected: false }),
    }),
  }),
});

export const { useGetRobotsQuery, useGetRobotsByIdQuery } = robotApi;
