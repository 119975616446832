import {
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  GLOBE,
  HALL_LOGO,
  HOME,
  OBJECT_LOGO,
  OBJECT_LOGO_TEXT,
  PAUSE,
  PLAY,
  PLUS,
  TROPHY_1,
  TROPHY_2,
  TROPHY_3,
  TROPHY_4,
  TROPHY_5,
  X_ICON,
} from './collections';

export enum ICON_NAMES {
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  OBJECT_LOGO = 'OBJECT_LOGO',
  OBJECT_LOGO_TEXT = 'OBJECT_LOGO_TEXT',
  HALL_LOGO = 'HALL_LOGO',
  GLOBE = 'GLOBE',
  X_ICON = 'X_ICON',
  PLUS = 'PLUS',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  TROPHY_1 = 'TROPHY_1',
  TROPHY_2 = 'TROPHY_2',
  TROPHY_3 = 'TROPHY_3',
  TROPHY_4 = 'TROPHY_4',
  TROPHY_5 = 'TROPHY_5',
  HOME = 'HOME',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
}
export type ICON_TYPE = keyof typeof ICON_NAMES | ICON_NAMES;

export const ICON_COMPONENTS: Record<ICON_NAMES, (props: React.ComponentPropsWithRef<'svg'>) => JSX.Element> =
  {
    [ICON_NAMES.OBJECT_LOGO]: OBJECT_LOGO,
    [ICON_NAMES.CHEVRON_RIGHT]: CHEVRON_RIGHT,
    [ICON_NAMES.CHEVRON_LEFT]: CHEVRON_LEFT,
    [ICON_NAMES.HOME]: HOME,
    [ICON_NAMES.PLAY]: PLAY,
    [ICON_NAMES.PAUSE]: PAUSE,
    [ICON_NAMES.HALL_LOGO]: HALL_LOGO,
    [ICON_NAMES.OBJECT_LOGO_TEXT]: OBJECT_LOGO_TEXT,
    [ICON_NAMES.GLOBE]: GLOBE,
    [ICON_NAMES.X_ICON]: X_ICON,
    [ICON_NAMES.PLUS]: PLUS,
    [ICON_NAMES.TROPHY_1]: TROPHY_1,
    [ICON_NAMES.TROPHY_2]: TROPHY_2,
    [ICON_NAMES.TROPHY_3]: TROPHY_3,
    [ICON_NAMES.TROPHY_4]: TROPHY_4,
    [ICON_NAMES.TROPHY_5]: TROPHY_5,
  };
