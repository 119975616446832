import { Typography } from '@/core/ui';
import { cn } from '@/lib';

export const Maintenance = () => (
  <div
    className={cn(
      'flex h-screen w-full items-center justify-center',
      'bg-objects bg-apple bg-cover bg-center bg-no-repeat',
    )}
  >
    <div
      className={cn(
        'w-full lg:max-w-3xl max-w-xl rounded-md bg-chalk py-56 shadow-xl',
        'flex flex-col items-center justify-center space-y-12',
      )}
    >
      <img alt='Football' className='h-32 w-full' height='21' src='/logo.svg' width='100%' />
      <div className='text-center flex flex-col space-y-2'>
        <Typography className='text-4xl text-content1'>We are sorry for the trouble</Typography>
        <Typography className='text-2xl  text-content1'>The site is under maintaindence</Typography>
      </div>
    </div>
  </div>
);
