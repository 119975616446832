import { appConfigData } from '@/mocks';
import type { AppConfig } from '@/types';
import { changeCssVar } from '@/utils';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
  isIdle: boolean;
  globalLoading: boolean;
  appConfig: AppConfig | null;
}

const initialState: GlobalState = {
  isIdle: false,
  globalLoading: true,
  appConfig: appConfigData,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeIdle: (state, action: PayloadAction<GlobalState['isIdle']>) => {
      state.isIdle = action.payload;
    },
    setGlobalLoading: (state, action: PayloadAction<GlobalState['globalLoading']>) => {
      state.globalLoading = action.payload;
    },
    setAppConfig: (state, action: PayloadAction<GlobalState['appConfig']>) => {
      if (!action.payload) {
        return;
      }
      state.appConfig = Object.assign({}, action.payload);
      changeCssVar('--color-content1', action.payload.color);
      changeCssVar('--color-apple', action.payload.backgroundColor);
    },
  },
});

export const { changeIdle, setAppConfig, setGlobalLoading } = globalSlice.actions;

export default globalSlice.reducer;
