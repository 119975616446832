import { Loading, Maintenance, NotFound, ScreenSaver } from '@/core/blocks';
import { Route, Routes } from 'react-router-dom';
import { ROUTES, envVars } from './config';
import { useIdle } from './core';
import Home from './pages/home';
import ObjectDetail from './pages/object/id';
import { PrivateRoutes } from './router';

function App() {
  const { globalLoading } = useIdle();

  if (envVars.underMaintenance) {
    return <Maintenance />;
  }

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <ScreenSaver />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTES.HOME} Component={Home} />
          <Route path={ROUTES.OBJECT.view} Component={ObjectDetail} />
        </Route>
        <Route
          path={ROUTES.LOGIN}
          element={<div className='h-screen flex justify-center items-center text-8xl'>Login</div>}
        />
        <Route path='*' Component={NotFound} />
      </Routes>
    </>
  );
}

export default App;
