import { Skeleton } from '@/core/ui';
import { cn } from '@/lib';
import { cx } from 'class-variance-authority';

export const ObjectDetailSkeleton = () => {
  return (
    <div className='grid bg-apple h-full w-full shadow-container grid-cols-8 rounded-2xl relative'>
      <Skeleton className={cn('absolute top-8 right-8', 'px-12 py-6 rounded-full items-center')} />
      <div
        className={cx('bg-contain bg-bottom bg-olive', 'rounded-tl-2xl rounded-bl-2xl col-span-3 relative')}
      >
        <div className='flex justify-center h-full items-center'>
          <Skeleton className='h-[630px] w-[430px] rounded-[28px]' />
        </div>
      </div>
      <div className='px-28 pb-24 pt-28 flex flex-col justify-between col-span-5'>
        <div className='flex flex-col space-y-14'>
          <div className='flex flex-row justify-between'>
            <div className=''>
              <Skeleton className='w-[500px] h-[45px]' />
            </div>
            <div className=''>
              <Skeleton className='w-[160px] h-[50px]' />
            </div>
          </div>
          <div className=''>
            <Skeleton className='w-[44rem] h-[420px]' />
            {/* <br /> */}
            {/* <br />
            <Skeleton className='w-[44rem] h-[100px]' /> */}
          </div>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <Skeleton className='h-[50px] w-[200px]' />
          <Skeleton className='h-[50px] w-[250px]' />
        </div>
      </div>
    </div>
  );
};
