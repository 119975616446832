import { ObjectDetails } from '@/components/object';

const ObjectView = () => {
  return (
    <div className='bg-apple w-full h-screen flex p-28 justify-center items-center bg-objects'>
      <ObjectDetails />
    </div>
  );
};
export default ObjectView;
