import { envVars } from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { globalApi, globalReducer } from './global';
import { objectApi } from './object';
import { oneCoreApi } from './onecore';
import { countReducer, robotApi } from './robots';

export const store = configureStore({
  reducer: {
    counter: countReducer,
    global: globalReducer,
    [robotApi.reducerPath]: robotApi.reducer,
    [objectApi.reducerPath]: objectApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [oneCoreApi.reducerPath]: oneCoreApi.reducer,
  },
  devTools: !envVars.prod,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      robotApi.middleware,
      globalApi.middleware,
      objectApi.middleware,
      oneCoreApi.middleware,
    );
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
