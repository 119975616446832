type CSSVar =
  | '--color-content1'
  | '--color-content2'
  | '--color-apple'
  | '--color-olive'
  | '--color-chalk'
  | '--color-danger';

export const changeCssVar = (name: CSSVar, value: string) => {
  document.documentElement.style.setProperty(name, value);
};
