import { useEffect, useState } from 'react';

type Props = React.ComponentProps<'img'> & {
  alternateImg?: string;
  prefixUrl?: string;
};

export const Image = ({ src, alternateImg, prefixUrl = '', ...props }: Props) => {
  const [sourcePath, setSourcePath] = useState(`${prefixUrl}${src}`);
  const defaultImg = '/objects/portugal-flag-1.png';

  useEffect(() => {
    if (src) {
      setSourcePath(`${prefixUrl}${src}`);
    }
  }, [src, prefixUrl]);

  const onError = () => {
    setSourcePath(alternateImg || defaultImg);
  };

  // if (!sourcePath) {
  //   // biome-ignore lint/a11y/useAltText: <explanation>
  //   return <img src={alternateImg || defaultImg} {...props} />;
  // }

  // biome-ignore lint/a11y/useAltText: <explanation>
  return <img src={sourcePath} onError={onError} {...props} />;
};
