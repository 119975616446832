const LANGUAGE_PATH = '/locales/{{lng}}/translations.json';
const MODAL_CONTAINER_ID = 'modal-container';
const MODAL_CONTAINER_ID2 = 'modal-container-2';
const DREAMIA_BODY = 'dreamia-body';

const COOKIES_KEY = {
  STOCK_MODAL: 'STOCK_MODAL',
  FOOTBALL_THEME: 'DREAMIA_THEME',
  FOOTBALL_JWT_TOKEN: 'DREAMIA_JWT_TOKEN',
};

const LOCAL_KEY = {
  CURRENT_LOCALE: 'i18nextLng',
};

export { LOCAL_KEY, LANGUAGE_PATH, DREAMIA_BODY, COOKIES_KEY, MODAL_CONTAINER_ID, MODAL_CONTAINER_ID2 };
