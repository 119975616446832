export const PLUS = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_b_2339_8848)'>
      <circle cx='44.55' cy='44.55' r='44.55' fill={'rgb(var(--color-color-apple))'} fillOpacity='0.25' />
    </g>
    <g filter='url(#filter1_b_2339_8848)'>
      <rect
        x='14.7125'
        y='14.7125'
        width='59.675'
        height='59.675'
        rx='29.8375'
        stroke={color ?? 'rgb(var(--color-chalk))'}
        strokeWidth='1.925'
      />
      <path
        d='M44.55 29.1499L44.55 59.9499'
        stroke={color ?? 'rgb(var(--color-chalk))'}
        strokeWidth='1.925'
        strokeLinejoin='round'
      />
      <path
        d='M59.95 44.5498L29.15 44.5498'
        stroke={color ?? 'rgb(var(--color-chalk))'}
        strokeWidth='1.925'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_b_2339_8848'
        x='-22'
        y='-22'
        width='133.1'
        height='133.1'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='11' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2339_8848' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_2339_8848' result='shape' />
      </filter>
      <filter
        id='filter1_b_2339_8848'
        x='8.19819'
        y='8.19819'
        width='72.7036'
        height='72.7037'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.77591' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2339_8848' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_2339_8848' result='shape' />
      </filter>
    </defs>
  </svg>
);
