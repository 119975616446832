import { envVars } from '@/config';
import { objects } from '@/mocks/object';
import type { IGetObjectByIdReq, IGetObjectByIdRes, IGetObjectsReq, IGetObjectsRes } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const objectApi = createApi({
  // // Reducing subscription time
  // keepUnusedDataFor: 30,
  // // Configuring re-fetching on subscription if data exceeds a given time
  // refetchOnMountOrArgChange: 30,
  // // Re-fetching on network reconnection
  // refetchOnReconnect: true,
  // // Re-fetching on window focus
  // refetchOnFocus: true,
  reducerPath: 'objectApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['objects'],
  endpoints: (builder) => ({
    getObjects: builder.query<IGetObjectsRes['data'], IGetObjectsReq>({
      query: () => ({
        url: '/history/objects',
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Objects fetched Successfully',
          data: { objects },
        },
      }),
      transformResponse: (response: IGetObjectsRes) => response.data ?? {},
    }),
    getObjectById: builder.query<IGetObjectByIdRes['data']['object'], IGetObjectByIdReq>({
      query: ({ id }) => ({
        url: `/history/object/${id}`,
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Object fetched Successfully',
          data: { object: objects.find((o) => o.id === id) },
        },
      }),
      transformResponse: (response: IGetObjectByIdRes) => response.data?.object ?? {},
    }),
  }),
});

export const { useGetObjectsQuery, useGetObjectByIdQuery } = objectApi;
