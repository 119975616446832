import { LANGUAGES, envVars } from '@/config';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import { HMRPlugin } from 'i18next-hmr/plugin';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

const LANGUAGE_PATH = '/locales/{{lng}}/{{ns}}.json';

const initI8n = () => {
  const i18n = i18next.createInstance();

  i18n.use(ChainedBackend).use(LanguageDetector).use(initReactI18next);

  if (!envVars.prod) {
    i18n.use(new HMRPlugin({ vite: { client: true } }));
  }

  i18n
    .init({
      backend: {
        loadPath: LANGUAGE_PATH,
        backends: [HttpBackend, LocalStorageBackend],
        backendOptions: [
          {
            expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          },
          { loadPath: LANGUAGE_PATH },
        ],
        cacheHitMode: 'refreshAndUpdateStore',
      },
      lng: LANGUAGES.PORTUGUESE,
      supportedLngs: [LANGUAGES.ENGLISH, LANGUAGES.SPANISH, LANGUAGES.FRENCH, LANGUAGES.PORTUGUESE],
      fallbackLng: LANGUAGES.PORTUGUESE,
      ns: ['translation'],
      preload: [LANGUAGES.PORTUGUESE],
      debug: !envVars.prod,
      interpolation: {
        escapeValue: false,
      },
    })
    .catch((err: Error) => {
      console.error('i18n Error::', err);
    });

  return { i18n };
};
export { initI8n };
