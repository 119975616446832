import { APP_TYPE, envVars } from '@/config';
import { useAppDispatch, useAppSelector } from '@/store';
import { changeIdle } from '@/store/actions';
import { useGetConfigQuery } from '@/store/global/api';
import { useIdleTimer } from 'react-idle-timer/legacy';

export const useIdle = () => {
  const { isLoading } = useGetConfigQuery({ app: APP_TYPE.OBJECT });
  const dispatch = useAppDispatch();
  const isIdle = useAppSelector((s) => s.global.isIdle);
  const globalLoading = useAppSelector((s) => s.global.globalLoading);
  const appConfig = useAppSelector((s) => s.global.appConfig);

  // const onAction = (_event?: Event, idleTimer?: IIdleTimer) => {
  //   if (idleTimer?.isPrompted()) {
  //     idleTimer.activate();
  //   }
  //   return;
  // };

  // const onActive = (_event?: Event, idleTimer?: IIdleTimer) => {
  //   if (idleTimer?.isPrompted()) {
  //     setOpen(false);
  //   }
  // };

  // const onPrompt = () => {
  //   setOpen(true);
  // };

  const onIdle = () => {
    dispatch(changeIdle(true));
  };

  const { reset } = useIdleTimer({
    timeout: (appConfig?.idleTimeout ?? 10) * 1000,
    throttle: 500,
    disabled: envVars.underMaintenance || globalLoading || isLoading,
    // promptBeforeIdle: 1000 * 30,
    // onAction,
    // onActive,
    // onPrompt,
    onIdle,
  });

  const onReset = () => {
    dispatch(changeIdle(false));
    reset();
  };

  return {
    onReset,
    globalLoading,
    isIdle,
  };
};
