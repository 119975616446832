import { appConfigData } from '@/mocks';

class WebSocketService {
  private static instance: WebSocketService;
  private socket: WebSocket | null = null;
  private isConnected = false;
  private appName = appConfigData.appTitle;

  private constructor() {}

  static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  connect(ipAddress: string, token: string) {
    const url = `ws://${ipAddress}`;

    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      const signInPayload = { tags: [this.appName], key: token };
      const signInMessage = `%SIGN_IN%%1%${JSON.stringify(signInPayload)}`;
      this.socket?.send(signInMessage);
      console.log('WS:: connected and sign-in message sent:', signInMessage);
      this.isConnected = true;
    };

    this.socket.onmessage = (event) => {
      console.log('WS:: message:', event.data);
    };

    this.socket.onclose = () => {
      console.log('WS:: disconnected.');
      this.isConnected = false;
    };

    this.socket.onerror = (error) => {
      console.error('WS:: error:', error);
    };
  }

  pushCommand(deviceId: number, channel: number, value: number) {
    if (!this.socket || !this.isConnected) {
      console.error('WS:: Cannot push command. WebSocket is not connected.');
      return;
    }

    const commandOptions = {
      devices: [[deviceId]],
      options: {
        command: 'setChannelValue',
        channel: channel,
        value: value,
      },
    };
    const message = `%PUSH_COMMAND%%1%${JSON.stringify(commandOptions)}`;
    this.socket.send(message);
    console.log('WS:: Push command message sent:', message);
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      console.log('WS:: WebSocket connection closed.');
    }
  }
}

export default WebSocketService;
