import { envVars } from '@/config';
import { appConfigData } from '@/mocks';
import type {
  IGetAppConfigReq,
  IGetAppConfigRes,
  IGetAppScreensaverReq,
  IGetAppScreensaverRes,
  IScreenSaver,
} from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';
import { setAppConfig, setGlobalLoading } from './slice';

export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['global'],
  endpoints: (builder) => ({
    getConfig: builder.query<IGetAppConfigRes, IGetAppConfigReq>({
      query: () => ({
        url: '/config',
        isProtected: false,
        forceMock: true,
        mockData: {
          message: 'Config fetched Successfully',
          data: appConfigData,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setGlobalLoading(false));
        dispatch(setAppConfig(data.data));
      },
    }),
    getScreenSaver: builder.query<IScreenSaver, IGetAppScreensaverReq>({
      query: () => ({
        url: '/hall-of-champions/screensaver',
        isProtected: false,
      }),
      transformResponse: (response: IGetAppScreensaverRes) => response.data?.screensaver ?? null,
    }),
  }),
});

export const { useGetConfigQuery, useGetScreenSaverQuery } = globalApi;
