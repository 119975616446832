import { LANGUAGES, LOCAL_KEY } from '@/config';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { Storage } from './storage';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

export const getUnixTimestamp = (date: string | Date): number => {
  const inputDate = dayjs(date);
  const unixTimestamp = inputDate.valueOf() / 1000;
  return unixTimestamp;
};

export const getFormattedDate = (
  date: number | string | Date,
  format: string | undefined = 'DD MMMM YYYY',
) => {
  const lang = Storage.local.getRaw<LANGUAGES>(LOCAL_KEY.CURRENT_LOCALE) ?? LANGUAGES.PORTUGUESE;
  const formatted = dayjs(date).locale(lang).format(format);
  return formatted;
};

export const convertToSeconds = (date: string | Date) => {
  const endDate = dayjs(date);
  const hour = endDate.hour();
  const minute = endDate.minute();
  const seconds = endDate.second();
  const finalSeconds = hour * 3600 + minute * 60 + seconds;
  return finalSeconds;
};
