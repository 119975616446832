import { Skeleton } from '@/core/ui';
import { cn } from '@/lib';

export const ObjectCardSkeleton = ({ className, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div className={cn('bg-apple', className)} {...props}>
      <Skeleton className='h-[400px] w-[404px] rounded-[28px]' />
    </div>
  );
};
