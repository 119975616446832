import WebSocketService from '@/lib/websocket';
import { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import { API } from '../api';

interface SocketContextType {
  disconnect: () => void;
  connect: (ipAddress: string, token: string) => void;
  pushCommand: (deviceId: number, channel: number, value: number) => void;
}

export const useWebSocketService = () => {
  const { data: oneCoreConfig, isLoading, isError } = API.oneCoreApi.useGetOneCoreConfigQuery();
  const service = WebSocketService.getInstance();

  useEffect(() => {
    if (isLoading || !oneCoreConfig || !oneCoreConfig.data?.settings) {
      console.warn('Configuration Loading....');
      return;
    }

    if (isError || !oneCoreConfig.data?.settings) {
      console.error('Configuration not available or invalid.');
      return;
    }

    const { IP_ADDRESS, TOKEN } = oneCoreConfig.data.settings;
    if (!IP_ADDRESS || !TOKEN) {
      console.error('Invalid configuration: Missing IP or Token.');
      return;
    }

    service.connect(IP_ADDRESS, TOKEN);

    return () => {
      service.disconnect();
    };
  }, [oneCoreConfig, isLoading, isError]);

  return service;
};

const SocketContext = createContext<SocketContextType>({
  disconnect: () => {},
  connect: () => {},
  pushCommand: () => {},
});

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const value = useWebSocketService();

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
