export const PLAY = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg
    width='108'
    height='108'
    viewBox='0 0 108 108'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g filter='url(#filter0_b_2467_1090)'>
      <rect
        x='1.35249'
        y='1.12104'
        width='105.378'
        height='105.378'
        rx='52.689'
        stroke='#FCFBF9'
        strokeWidth='2.24208'
      />
      <path d='M69.4157 53.81L46.3543 72.0299L46.3543 35.5901L69.4157 53.81Z' fill='#FCFBF9' />
    </g>
    <defs>
      <filter
        id='filter0_b_2467_1090'
        x='-4.38734'
        y='-4.61878'
        width='116.858'
        height='116.858'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.30939' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2467_1090' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_2467_1090' result='shape' />
      </filter>
    </defs>
  </svg>
);
