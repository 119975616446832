import { JSONParse } from '../utils';
import { ENV_MODE } from './enums';

const prod = import.meta.env.VITE_NODE_ENV === ENV_MODE.PRODUCTION;
const envVars = {
  env: import.meta.env.VITE_NODE_ENV,
  prod,
  baseUrl: prod ? `https://${import.meta.env.VITE_BASE_URL}/` : import.meta.env.VITE_BASE_URL,
  screenDebugger: (JSONParse(import.meta.env.VITE_CSS_SCREEN_DEBUGGER as string) as boolean) ?? false,
  backendUrl: import.meta.env.VITE_BACKEND_URL,
  underMaintenance: (JSONParse(import.meta.env.VITE_UNDER_MAINTENANCE as string) as boolean) ?? false,
  socket: {
    key: import.meta.env.VITE_WEBSOCKET_KEY,
    url: import.meta.env.VITE_WEBSOCKET_URL,
  },
};

export { envVars };
