const ROUTES = {
  MAIN: '/',
  HOME: '/',
  LOGIN: '/login',
  OBJECT: {
    main: '/object',
    view: '/object/:id',
  },
  ROBOT: { list: '/robots', view: '/robot/:id' },
};

export { ROUTES };
