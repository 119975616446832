export const HOME = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      id='Vector'
      d='M13.3771 1.64619L22.7744 11.0435C23.6615 11.9306 23.0334 13.4472 21.7787 13.4472H20.9952C20.8024 13.4472 20.6456 13.604 20.6456 13.7968V21.1445C20.6456 22.0589 19.9046 22.8 18.9902 22.8H15.6116C15.4519 22.8 15.3217 22.6705 15.3217 22.51V17.6308C15.3217 16.5717 14.4634 15.7127 13.4037 15.7127H12.3001H11.1964C10.1374 15.7127 9.2784 16.571 9.2784 17.6308V22.51C9.2784 22.6697 9.1489 22.8 8.98846 22.8H5.60996C4.69555 22.8 3.95452 22.0589 3.95452 21.1445V13.7968C3.95452 13.604 3.79769 13.4472 3.60488 13.4472H2.8214C1.5667 13.4472 0.938622 11.9306 1.82569 11.0435L11.2231 1.64619C11.818 1.05121 12.7821 1.05121 13.3771 1.64619Z'
      stroke={color ?? 'white'}
      strokeWidth='1.6875'
      strokeMiterlimit='10'
    />
  </svg>
);
