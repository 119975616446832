export const CHEVRON_RIGHT = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='17' height='28' viewBox='0 0 17 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      id='Vector'
      d='M2.03455 26L14.0345 14L2.03455 2'
      stroke={color ?? 'rgb(var(--color-chalk))'}
      strokeWidth='3'
    />
  </svg>
);
