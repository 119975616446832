import { HomeHeading, ObjectList } from '@/components/home';
import { Header } from '@/layout';

const Home = () => {
  return (
    <div className='h-screen bg-apple bg-contain bg-left-top bg-no-repeat bg-objects'>
      <div className='flex flex-col relative h-full'>
        <div className=''>
          <Header />
        </div>
        <div className='flex space-y-12 mt-8 flex-col justify-center w-full text-center items-center h-full'>
          <HomeHeading />
          <ObjectList />
        </div>
      </div>
    </div>
  );
};

export default Home;
