export enum ENV_MODE {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}
export enum LANGUAGES {
  FRENCH = 'fr',
  SPANISH = 'es',
  ENGLISH = 'en',
  PORTUGUESE = 'pt',
}

export enum VIEW_MODE {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum FORM_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum APP_TYPE {
  HALL = 'HALL',
  OBJECT = 'OBJECT',
  SHIRT = 'SHIRT',
}
