import { cn } from '@/lib';

export const Typography = ({ children, className, ...props }: React.ComponentProps<'span'>) => {
  return (
    <span
      {...props}
      className={cn(
        'line-[27.6px] tracking-[1.2px] text-2xl',
        'font-united-sans font-medium text-content1',
        className,
      )}
    >
      {children}
    </span>
  );
};
