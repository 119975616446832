import { envVars } from '@/config';
import { UIInspector, initI8n } from '@/lib';
import { store } from '@/store';
import { SocketProvider } from '@/store/context';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

export function Providers({ children }: Props) {
  const { i18n } = initI8n();
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <SocketProvider>
            <UIInspector enabled={envVars.screenDebugger} />
            {children}
          </SocketProvider>
          {/* {auth?.requireAuth ? <Protected auth={auth}>{children}</Protected> : children} */}
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
}
