import { Card, CardContent, Image } from '@/core/ui';
import type { IObject } from '@/types';

interface IProps {
  name: IObject['name'];
  url: IObject['image']['path'];
}

export const ObjectCard = ({ name, url }: IProps) => {
  return (
    <Card className='w-[404px] shadow-container h-[400px] rounded-[28px]'>
      <CardContent className='flex p-0 justify-center items-center h-full'>
        <Image src={url} alt={name} className='w-auto h-72' />
      </CardContent>
    </Card>
  );
};
