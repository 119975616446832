export const X_ICON = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_b_2339_8847)'>
      <rect
        x='0.920455'
        y='0.920455'
        width='52.1591'
        height='52.1591'
        rx='26.0795'
        stroke={color ?? 'rgb(var(--color-danger))'}
        strokeWidth='1.84091'
      />
      <path
        d='M17.4539 17.4542L36.5457 36.5461'
        stroke={color ?? 'rgb(var(--color-danger))'}
        strokeWidth='1.84091'
        strokeLinejoin='round'
      />
      <path
        d='M36.5457 17.4541L17.4539 36.546'
        stroke={color ?? 'rgb(var(--color-danger))'}
        strokeWidth='1.84091'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_b_2339_8847'
        x='-4.86685'
        y='-4.86685'
        width='63.7337'
        height='63.7337'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.43342' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2339_8847' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_2339_8847' result='shape' />
      </filter>
    </defs>
  </svg>
);
